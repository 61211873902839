import * as React from "react";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";

const NotFoundPage = () => (
  <Layout>
    <section className="section">
          <div className="container">
            <div className="content">
            <div>
              <h1>NOT FOUND</h1>
              <p>It's not you, it's us. </p> 
              <p>We recently migrated the entire blog from one platform to another and we screwed up some of the links...</p>
            </div>
              <BlogRoll />
            </div>
          </div>
        </section>
  </Layout>
);

export default NotFoundPage;
